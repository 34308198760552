
import React from 'react';
import './styles.css';

const Footer = () => {
  return (
    <footer>
      <p>Made with passion 💌 TermScout 2024.</p>
    </footer>
  );
};

export default Footer;
